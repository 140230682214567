// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getRemoteConfig } from 'firebase/remote-config';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyDL9Spr1qgsN2tMDcEQy4lX5G0UTF82I10',
    authDomain: 'ideafunding2022.firebaseapp.com',
    projectId: 'ideafunding2022',
    storageBucket: 'ideafunding2022.appspot.com',
    messagingSenderId: '99053630632',
    appId: '1:99053630632:web:f8ee0fa0693baabca33e9d'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const remoteConfig = getRemoteConfig(app);
export const functions = getFunctions(app);

if (process.env.FUNCTIONS_EMULATOR) {
    console.log('emulating');
    connectFunctionsEmulator(functions, 'localhost', 5001);
}

remoteConfig.defaultConfig = {};
