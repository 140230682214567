// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled, { css } from 'styled-components';

import HivemetricLogo from './../Branding/HivemetricLogo';

const FooterActual = styled.footer`
    border-top: 1px solid #eee;
    background: ${({ theme }) => theme.colors.background.tertiary};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
`;

const ContentWrapper = styled.div`
    display: flex;
    ${'' /* flex-direction: row-reverse; */}
    ${'' /* align-items: stretch; */}
    flex-direction: column;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 1.5rem 0;
    justify-content: space-between;
    gap: 1.5rem;
    ${(props) => props.theme.responsive.device} {
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 2rem;
    }
`;

const SponsorGroup = styled.div`
    display: flex;
    flex-direction: column;
    ${'' /* flex-direction: column; */}
    justify-content: start;
    width: 100%;
    
    ${(props) => props.theme.responsive.device} {
        img {
            flex:
        }
        width: 100%;
    }
    ${({ horizontal }) => horizontal && css`
        align-items: center;
        gap: 1rem;
        flex-direction: row;
        flex-grow: 0;
        justify-content: flex-start;
        width: auto;
        justify-content: center;
    `}
`;

const LogoGroup = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 4rem;
    align-items: center;
    gap: 16px;
    height: auto;
    div {
        flex-grow: 1;
        align-items: center;
        display: flex;
        justify-content: center;
    }
    ${(props) => props.theme.responsive.device} {
        width: 100%;
        justify-content: space-around;
        div {
            flex-basis: 35%;
        }
    }
`;

const SponsorsLogoGroup = styled(LogoGroup)`
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 1rem;
`;

const HivemetricLogoGroup = styled(LogoGroup)`
    justify-content: center;
    gap: 1rem;
    flex-grow: 0;
    align-items: center;
    width: auto;
`;

const PoweredBy = styled.h3`
    color: ${(props) => props.theme.colors.text.muted};
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0 0 0.3rem 0;
    text-align: center;
    ${(props) => props.theme.responsive.sff} {
        font-size: 0.9rem;
    }
    user-select: none;

    ${({ inline }) => inline && css`
        white-space: nowrap;
        margin: 0;
    `};
`;

const HiveLogo = styled(HivemetricLogo)`
    width: 10rem;
    ${(props) => props.theme.responsive.sff} {
        width: 10rem;
    }
`;

const StartupTucsonLogo = styled.img`
    height: 4.5rem;
    /* ${(props) => props.theme.responsive.sff} {
        width: 10rem;
    } */
`;

const UAICLogo = styled.img`
    height: 2.5rem;
    /* ${(props) => props.theme.responsive.sff} {
        width: 10rem;
    } */
`;

export const Footer = () => {
    return (
        <FooterActual>
            <ContentWrapper>
                <SponsorGroup>
                    <PoweredBy>IdeaFunding 2025 hosted by</PoweredBy>
                    <SponsorsLogoGroup>
                        <div>
                            <StartupTucsonLogo
                                src={`${process.env.PUBLIC_URL}/Startup_Logos_2020-Large_Navy.png`}
                            />
                        </div>
                        <div>    
                            <UAICLogo
                                src={`${process.env.PUBLIC_URL}/2019_UACI_Logo.png`}
                            />
                        </div>
                        <div>
                            <UAICLogo
                                src={`${process.env.PUBLIC_URL}/arizona_commerce_authority_logo.svg`}
                            />
                        </div>
                        <div>
                            <UAICLogo
                                src={`${process.env.PUBLIC_URL}/vantage_west_logo.svg`}
                            />
                        </div>
                    </SponsorsLogoGroup>
                </SponsorGroup>
                <SponsorGroup horizontal>
                    <PoweredBy inline>Voting powered by</PoweredBy>
                    <HivemetricLogoGroup>
                        <a
                            href="https://www.hivemetric.com/?utm_source=vote.ideafunding.org&utm_medium=event_sponsorship&utm_campaign=ideafunding2019"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <HiveLogo />
                        </a>
                    </HivemetricLogoGroup>
                </SponsorGroup>
            </ContentWrapper>
        </FooterActual>
    );
};

Footer.propTypes = {};

export default Footer;
